import React from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as KeepingInTouch } from '@vega/components/src/assets/images/keeping-in-touch.svg'
import { ReactComponent as WhiteCircle } from '@vega/components/src/assets/images/white-circle.svg'
import { WelcomeSection } from './WelcomeSection'
import { CreateAccountSection } from './CreateAccountSection'

const Root = styled.div(
  s('relative flex', {
    minHeight: '900px',
  })
)
const Gradient = styled.div({
  borderTopRightRadius: '40px',
  borderBottomRightRadius: '40px',
  width: '596px',
  backgroundImage: 'linear-gradient(#00FFCD,#00FFCD)',
})
const CircleImage = styled(WhiteCircle)(s('absolute pin-bl-0'))
const KeepingInTouchImage = styled(KeepingInTouch)(s('absolute pin-bl-0'))

const CreateClientAccount = () => (
  <Root>
    <Gradient>
      <WelcomeSection />
    </Gradient>
    <CreateAccountSection />
    <CircleImage />
    <KeepingInTouchImage />
  </Root>
)

export { CreateClientAccount }
