import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { invitationService } from '@vega/auth/src/apiService'
import { normalizeError } from '@vega/services'

export const getInvitation = createAsyncThunk(
  'invitation/getInvitation',
  async (code, { rejectWithValue }) => {
    try {
      const invitation = await invitationService.getInvitation(code)
      return invitation
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const acceptInvitation = createAsyncThunk(
  'invitation/acceptInvitation',
  async (formValues, { rejectWithValue }) => {
    try {
      return await invitationService.acceptInvitation(formValues)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const invitationSlice = createSlice({
  name: 'invitation',
  initialState: {
    invitationCode: '',
    email: '',
    role: '',
  },
  reducers: {},
  extraReducers: {
    [getInvitation.fulfilled]: (state, action) => {
      state.invitationCode = action.payload.invitationCode
      state.email = action.payload.email
      state.role = action.payload.role
    },
  },
})

export default invitationSlice.reducer

const { reducer: invitationReducer } = invitationSlice

export { invitationReducer }
