import React from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as WavyBg } from '@vega/components/src/assets/images/createBrokerAccBg.svg'
import { WelcomeSection } from './WelcomeSection'
import { CreateAccountSection } from './CreateAccountSection'

import keepInTouch from '@vega/components/src/assets/images/keeping-in-touch.png'

const Root = styled.div(
  s('relative flex', {
    overflow: 'hidden',
    height: '900px',
  })
)

const WavyBgContainer = styled.div(s('absolute z-0 pin-tl-0'))

const KeepInTouchImg = styled.img(s('absolute pin-bl-0'))

function CreateAccount() {
  return (
    <Root>
      <WavyBgContainer>
        <WavyBg />
      </WavyBgContainer>

      <WelcomeSection />

      <CreateAccountSection />

      <KeepInTouchImg src={keepInTouch} />
    </Root>
  )
}

export { CreateAccount }
