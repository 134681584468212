import React from 'react'
import { Formik as FormProvider, Form } from 'formik'
import * as Validator from 'yup'
import { styled, s } from '@vega/styled'
import { FormInput, PasswordInput, Button } from '@vega/components'
import { useDispatch, useSelector } from 'react-redux'
import {
  acceptInvitation,
  selectHasAcceptInvitationSucceeded,
} from 'modules/invitation'
import { Redirect } from 'react-router'
import { TEST_IDS, SOLTA_CONSTANTS } from '@vega/constants'

const { object, string } = Validator
const validationSchema = object({
  invitationCode: string().required().label('Invitation Code'),
  firstName: string().required().label('First Name'),
  lastName: string().required().label('Last Name'),
  password: string()
    .required()
    .label('Password')
    .matches(
      SOLTA_CONSTANTS.REGEX.MIN_PASSWORD_REQ_STRONG,
      'Password must contain at least 8 characters, one uppercase, one number'
    ),
})
const Details = styled.div(s('flex flex-column'))
const DualInputsContainer = styled.div(s('flex justify-between w-full mb-6'))

const CreateAccountForm = ({ invitationCode, email }) => {
  const dispatch = useDispatch()

  const hasAcceptInvitationSucceed = useSelector(selectHasAcceptInvitationSucceeded)

  if (hasAcceptInvitationSucceed) {
    return <Redirect to="/sign-in?firstsignin=true" />
  }

  return (
    <FormProvider
      enableReinitialize
      initialValues={{
        invitationCode,
        email,
        firstName: '',
        lastName: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => dispatch(acceptInvitation(values))}
    >
      <Form style={s('w-full')}>
        <Details>
          <FormInput
            name="invitationCode"
            label="Invite No"
            disabled
            testId={TEST_IDS.CLIENT_CREATION_INVITE_NO_TEXT}
            style={s('mb-6')}
          />

          <FormInput
            name="email"
            label="Email"
            disabled
            testId={TEST_IDS.CLIENT_CREATION_EMAIL_TEXT}
            style={s('mb-6')}
          />

          <DualInputsContainer>
            <FormInput
              name="firstName"
              label="Given name"
              testId={TEST_IDS.CLIENT_CREATION_FIRST_NAME_INPUT}
              style={s('mr-2 w-1/2')}
            />
            <FormInput
              name="lastName"
              label="Surname"
              testId={TEST_IDS.CLIENT_CREATION_SURNAME_INPUT}
              style={s('ml-2 w-1/2')}
            />
          </DualInputsContainer>

          <PasswordInput
            testId={TEST_IDS.CLIENT_CREATION_PASSWORD_INPUT}
            name="password"
            style={s('mb-16')}
          />
          <Button
            type="submit"
            size="md"
            testId={TEST_IDS.CLIENT_CREATION_SUBMIT_BUTTON}
            style={s('w-16 font-semibold py-4 mx-a')}
          >
            Get Started
          </Button>
        </Details>
      </Form>
    </FormProvider>
  )
}

export { CreateAccountForm }
