import { configureStore } from '@reduxjs/toolkit'
import { asyncStateReducer } from '@vega/redux.async'
import { profileReducer } from '@vega/redux.profile'
import { invitationReducer } from 'modules/invitation/slice'

export default configureStore({
  reducer: {
    asyncState: asyncStateReducer,
    invitation: invitationReducer,
    profile: profileReducer,
  },
})
