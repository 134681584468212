import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { CreateAccount } from './features/createAccount'
import { SignIn } from './features/signIn'
import { SignOut } from './features/signOut'
import { RPC } from './features/rpc'
import { TOTPSetup } from './features/mfa'

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/create-account" component={CreateAccount} />
      <Route path="/sign-in" component={SignIn} />
      <Route path="/sign-out" component={SignOut} />
      <Route path="/rpc" component={RPC} />
      <Route path="/setup-totp" component={TOTPSetup} />
      <Redirect to={'/sign-in'} />
    </Switch>
  </Router>
)

export default Routes
