import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AuthSdk as Wallet } from '@midas/idkit'
import Cookies from 'js-cookie'
import { isDev } from 'utils/isDev'

import { clearProfile } from '@vega/redux.profile'
import { AUTH } from '@vega/constants'

const VEGA_SUBDOMAIN = process.env.REACT_APP_VEGA_SUBDOMAIN

function SignOut() {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const loggedOut = async () => {
      const result = await Wallet.signOut()
      if (result.status === 'success') {
        Cookies.set(AUTH.AUTH_COOKIE_KEY, null, {
          domain: VEGA_SUBDOMAIN,
          secure: !isDev(),
          sameSite: 'strict',
        })

        Cookies.set(AUTH.AUTH_ID_TOKEN_KEY, null, {
          domain: VEGA_SUBDOMAIN,
          secure: !isDev(),
          sameSite: 'strict',
        })
      }
    }
    loggedOut().catch('Failed to log out')

    dispatch(clearProfile())
    history.replace('sign-in')
  }, [dispatch, history])
  return null
}

export { SignOut }
