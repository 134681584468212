import React from 'react'
import { Link } from 'react-router-dom'
import { styled, s } from '@vega/styled'
import { CreateAccountForm } from './CreateAccountForm'
import { useSelector } from 'react-redux'
import { selectInvitationCode, selectEmail } from 'modules/invitation/selectors'

const Container = styled.div(s('h-full pt-16 flex flex-column items-center w-32 mx-a'))
const TextContainer = styled.div(s('w-full'))
const Title = styled.p(s('text-grey-600 text-3xl mb-2'))
const InviteText = styled.p(s('text-grey-500 mt-0 mb-16'))
const BottomText = styled.p(s('text-grey-400 font-light'))
const SignInLink = styled(Link)(s('text-grey-600 font-normal'))

function CreateAccountSection() {
  const invitationCode = useSelector(selectInvitationCode)
  const email = useSelector(selectEmail)

  return (
    <Container>
      <TextContainer>
        <Title>Create a new account</Title>
        <InviteText>
          You have been invited to join Neo Lend’s online loan application management
          platform.
        </InviteText>
      </TextContainer>

      {invitationCode && email && (
        <CreateAccountForm invitationCode={invitationCode} email={email} />
      )}

      <BottomText style={s('mt-a mx-a')}>
        Already have an account with Neo Lend or Neo Private?{' '}
        <SignInLink to="/broker/sign-in">Sign in</SignInLink>
      </BottomText>
    </Container>
  )
}

export { CreateAccountSection }
